<script>
    import IconButton, {Icon} from '@smui/icon-button';

    let screenData = {};
    export const setScreen = (data) => {
        screenData = data;
    };
    let audioState;
    export const changeState = (state, hasPosition) => {
        if(!hasPosition) {
            audioState = null;
            return;
        }
        audioState = state;
    };
</script>
{#if screenData}
    <div id="screen">
        <div class="navigation row">
            <div>{screenData.navigation || ''}</div>
            <div>
                {#if audioState}
                    <Icon class="material-icons i-button">mic</Icon>
                {:else if !audioState}
                    <Icon class="material-icons i-button">volume_up</Icon>
                {/if}
            </div>
        </div>
        <div>{screenData.line1 || ''}</div>
        <div>{screenData.line2 || ''}</div>
        <div>{screenData.line3 || ''}</div>
    </div>
{/if}

<style>
#screen{
    position: fixed;
    z-index: 10;
    bottom: 30px;
    left: 50%;
    transform: translate(50%, 0);
    width: 400px;
    height: 250px;
    background-color: #222;
    color: #fece00;
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
}
:global(.i-button){
    font-size: 1em;
}
#screen div{
    font-size: 0.9em;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
#screen div.navigation{
    font-size: 0.6em;
    margin-bottom: 5px;
}
</style>