<script>

    import { onMount } from 'svelte';

    import { flip } from 'svelte/animate';
    import { quintOut } from 'svelte/easing';
	import { crossfade } from 'svelte/transition';

    import Button, { Label, Icon } from '@smui/button';

    export let list = [];
    let sortedList = [];

    let nestedElement;

    onMount(() => {
        nestedElement = document.querySelector('#io-chat');
        
    });

    const _sort = (a, b) => {
        if (a.timestamp < b.timestamp) {
            return -1;
        }
        if (a.timestamp > b.timestamp) {
            return 1;
        }
        return 0;
    }
    const _unique = (data) => {
        return  [...new Set(data.map(item => item))];
    }
    const _updateList = (list) => {
        list = _unique(list);
        list.sort(_sort);
        //list = list.slice(-10);
        sortedList = list;


        if (nestedElement) {
            nestedElement.scrollTo(0, nestedElement.scrollHeight);
        }

    }
    const deleteItem = (item) => {
        //list = list.filter((value) => value !== item);
    }
    const dayHourMinuteFromTimestamp = (timestamp) => {
        var days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];

        const date = new Date(timestamp);
        //const day = date.getDate();
        const day = days[date.getDay()];
        const hour = date.getHours();
        const minute = date.getMinutes();
        const second = date.getSeconds();
        return `${day} ${hour}:${minute}:${second}`;
    }

    const [send, receive] = crossfade({
		duration: (d) => Math.sqrt(d * 200),

		fallback(node, params) {
			const style = getComputedStyle(node);
			const transform = style.transform === 'none' ? '' : style.transform;

			return {
				duration: 600,
				easing: quintOut,
				css: (t) => `
					transform: ${transform} scale(${t});
					opacity: ${t}
				`
			};
		}
	});


    $: list && _updateList(list)
</script>
<div class="chat-list">
    <slot/>
    {#each sortedList as value, i (value.key)}
        <div in:receive={{ key: value.key }} out:send={{ key: value.key }} animate:flip>
            {#if value.type === 'message'}
                <div class="item item-{value.type}">
                    <div class="values row">
                        <div class="col-6">FoVis ( {dayHourMinuteFromTimestamp(value.timestamp)} ): {value.message}</div>
                    </div>
                </div>
            {:else if value.type === 'tree'}
                <a class="item item-{value.type}" on:click={() => value.centerOn([value.longitude, value.latitude], 21)} aria-label="focus Item">
                    <div class="item item-{value.type}">
                        <div class="sender row">
                            <div class="item-label">Helm</div>
                            <div class="col-6">
                                <Icon class="material-icons">pest_control</Icon>
                            </div>
                        </div>
                        <div class="values row">
                            <div class="item-label">Species</div>
                            <div class="col-6">{value.species}</div>
                        </div>
                        <div class="values row">
                            <div class="item-label">Height</div>
                            <div class="col-6">{value.height} Meter</div>
                        </div>
                        <div class="values row">
                            <div class="item-label">Diameter</div>
                            <div class="col-6">{value.dbh} Centimeter</div>
                        </div>
                        <div class="time">{dayHourMinuteFromTimestamp(value.timestamp)}</div>
                    </div>
                </a>
            {:else if value.type === 'polter'}
                <div class="item item-{value.type}">
                    <div class="sender row">
                        <div class="item-label">Rudi</div>
                        <div class="col-6">
                            <Icon class="material-icons">reorder</Icon>
                        </div>
                    </div>
                    <div class="values row">
                        <div class="item-label">Species</div>
                        <div class="col-6">{value.species}</div>
                    </div>
                    <div class="values row">
                        <div class="item-label">Height</div>
                        <div class="col-6">{value.height} Meter</div>
                    </div>
                    <div class="values row">
                        <div class="item-label">Diameter</div>
                        <div class="col-6">{value.dbh} Centimeter</div>
                    </div>
                    <div class="time">{dayHourMinuteFromTimestamp(value.timestamp)}</div>
                </div>
            {:else}
                <div class="item item-{value.type}">
                    <div class="sender">FoVis</div>
                    <div class="values">{value.species}</div>
                    <div class="time">{dayHourMinuteFromTimestamp(value.timestamp)}</div>
                </div>
            {/if}
        </div>
    {/each}
</div>

<style>
    .chat-list{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        overflow: auto;
        padding-bottom: 10px;
    }
    .sender{
        color: #000;
        font-size: 1rem;
        margin-bottom: 5px;
        font-weight: bold;
    }
    .time{
        text-align: right;
        font-size: 0.5rem;
        opacity: 0.9;
        margin-top: 5px;
    }
    .item{
        background: rgba(50, 50, 50, 1);
        padding: 10px 10px;
        margin: 5px 10px;
        border-radius: 15px;
        display: block;
    }
    
    .item-label{
        font-size: 0.8rem;
        font-weight: bold;
    }
    .item-tree{
        background: rgb(47, 72, 88);
    }
    .item-tree .sender{
        color: #fece00 !important;
    }
    .item-tree:hover{
        background: rgba(47, 72, 88, .5);
        cursor: pointer;
    }
    .item-message{
        color: #fece00 !important;
        font-size: 0.8rem;
    }
    .values{
        padding: 10px;
    }
    .item-message .values{
        padding: 0px;
    }
    
</style>