export default  {

    // 'deckgl' || 'leaflet' || 'openlayers'
    FRAMEWORK: 'deckgl'
};

const deckGlConfig = {
    // Alternative: verbissproz_ob_drittel
    GEOJSON_PROPERTY_NAME: 'value',
    GEOJSON_PROPERTY_NAME_READABLE: 'Verbissprozent',
    GEOJSON_PROPERTY_UNIT: '%',
    DEFAULT_HEXAGON_RADIUS: 500,
    TILE_SERVER: [
        'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
    ]
}

const persionalization = {
    //	https://frab.fossgis-konferenz.de/2022/img/Logo_Triangulation_online.png
    LOGO_URL: 'https://gruenecho.de/img/ge-logo.svg',
    PAGE_URL: 'https://gruenecho.de',
    REPOSITORY_URL: 'https://github.com/b-lack/create-svelte-deckgl-pwa-template'
}

export {deckGlConfig, persionalization};