<script>
    import * as THREE from 'three';
    import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
    import { onMount } from 'svelte';
    import Button, { Label, Icon } from '@smui/button';

    let camera, scene, renderer;
    let threeJsContainer;

    var CANVAS_WIDTH = 250;
    var CANVAS_HEIGHT = 250;

	let object;
    
    onMount(() => {
        init();
    });

    function init() {

        camera = new THREE.PerspectiveCamera( 45, CANVAS_WIDTH / CANVAS_HEIGHT, 0.1, 20 );
        camera.position.z = 2.5;

        // scene

        scene = new THREE.Scene();

        const ambientLight = new THREE.AmbientLight( 0xffffff );
        scene.add( ambientLight );

        const pointLight = new THREE.PointLight( 0xffffff, 15 );
        camera.add( pointLight );
        scene.add( camera );

        // manager

        function loadModel() {

            object.traverse( function ( child ) {

                if ( child.isMesh ) child.material.map = texture;

            } );

            //object.position.y = - 0.3;

            object.scale.setScalar( 6.4 );
            
            scene.add( object );

            render();

            
        }

        const manager = new THREE.LoadingManager( loadModel );

        // texture

        const textureLoader = new THREE.TextureLoader( manager );
        const texture = textureLoader.load( './asset/orange.jpg', render );
        texture.colorSpace = THREE.SRGBColorSpace;

        // model

        function onProgress( xhr ) {

            if ( xhr.lengthComputable ) {

                const percentComplete = xhr.loaded / xhr.total * 100;
                console.log( 'model ' + Math.round( percentComplete, 2 ) + '% downloaded' );

            }

        }

        function onError() {}

        const loader = new OBJLoader( manager );
        var obj ='./asset/construction-helmet-001-obj/construction-helmet-001.obj';
        //obj = 'https://raw.githubusercontent.com/mrdoob/three.js/master/examples/models/obj/male02/male02.obj';
        loader.load( obj, function ( obj ) {

            object = obj;

            

        }, onProgress, onError );

        //

        renderer = new THREE.WebGLRenderer( { antialias: true } );
        renderer.setClearColor( 0x000000, 0 ); // the default

        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize(CANVAS_WIDTH, CANVAS_HEIGHT);
        threeJsContainer.appendChild(renderer.domElement);

        //

        /*const controls = new  OrbitControls( camera, renderer.domElement );
        controls.minDistance = 2;
        controls.maxDistance = 5;
        controls.addEventListener( 'change', render );*/

        //

        //window.addEventListener( 'resize', onWindowResize );

    }

    function onWindowResize() {

        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize( window.innerWidth, window.innerHeight );

    }

    function render() {

        renderer.render( scene, camera );

    }
     let centerOn;
     let helmetPosition;
    export const rotateObject = (yaw, pitch, roll, longitude, latitude, newCenterOn) => {
        if (!object) return;

        object.rotation.y = -yaw-3.14159;
        object.rotation.x = -pitch-3.14159;
        object.rotation.z = -roll;
        centerOn = newCenterOn;
        helmetPosition = [longitude, latitude];
        renderer.render( scene, camera );
    };

    let audioState;
    export const changeState = (state) => {
        if(!audioState && state && helmetPosition) {
            centerOn(helmetPosition, 18);
        }
        audioState = state;
    };

    const _focus = () => {
        console.log('focus');
        centerOn(helmetPosition, 18);
    }

</script>

<div id="threeJsContainer" bind:this={threeJsContainer} on:click={() => _focus()} role="navigation" aria-label="Main">
    
</div>

<style>

    #audio-state{
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #222;
        width: 30px;
        height: 30px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        border-radius: 50%;
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.75);
    }
    #threeJsContainer {
        top: 20px;
        right: 20px;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin: 25px;
        /*box-shadow: 0 0 20px 0 rgba(0,0,0,0.75);*/
        background-color: #222;
        position: fixed;
        opacity: 1;
    }
    #threeJsContainer.helmet-acive {
        opacity: 1;
    }
    :global(#threeJsContainer canvas) {
        width: 100% !important;
        height: 100% !important;
    }
</style>