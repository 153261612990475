//import {ScatterplotLayer} from '@deck.gl/layers';
import {SimpleMeshLayer} from '@deck.gl/mesh-layers';
import {CubeGeometry, ConeGeometry, SphereGeometry} from '@luma.gl/engine'
import {ScatterplotLayer} from '@deck.gl/layers';

//import { getXYZ } from '$lib/get-color-from-position';


const getTreeLayer = (PREFIX, data) => {

    let newLayers =  [];


    newLayers = [...newLayers, new SimpleMeshLayer({
        id: PREFIX + '-trunk-mesh',
        visible: true,
        pickable: true,
        data: data,
        mesh: new ConeGeometry({
            radius: 1,
            height: 1,
            nvertical: 1
          }), // ASSUME 100 Meter
        getScale: d => [d.dbh/100,  d.height, d.dbh/100], // d.height
        getTranslation: d => [0, 0, d.height/2], // height / 2
        getOrientation: d => [0, 0, 90],
        getPosition: d => [d.longitude, d.latitude, 0],
        getFillColor: d => [38, 18, 1, 1],
        material: {
            ambient: 0.35,
            diffuse: 0.6,
            shininess: 32,
            specularColor: [30, 30, 30]
        }
    })];
    
    newLayers.push(
        new ScatterplotLayer({
            id: PREFIX + '-accuracy',
            data,
            pickable: false,
            opacity: 0.001,
            stroked: false,
            filled: true,
            radiusScale: 1,
            lineWidthMinPixels: 1,
            getPosition: d => {
                return [d.longitude, d.latitude];
            },
            getRadius: d => d.accuracy,
            getFillColor: d => [255, 206, 0],
            getLineColor: d => [0, 0, 0],
            getPolygonOffset: ({layerIndex}) => [0, -layerIndex * 2],
          })
    );

    newLayers = [...newLayers, new SimpleMeshLayer({
        id: PREFIX + '-crown-mesh',
        visible: true,
        pickable: true,
        data: data,
        mesh: new ConeGeometry({
            radius: 1,
            height: 1,
            nvertical: 1,
            nradial: 20,
            cap: false
          }), // ASSUME 100 Meter
        getScale: d => [d.dbh/100*4,  d.height*2/3, d.dbh/100*4], // d.height
        getTranslation: d => [0, 0, d.height*2/3], // height / 2
        getOrientation: d => [0, 0, 1 * 90],
        getPosition: d => [d.longitude, d.latitude, 0],
        getColor: d => [24, 83, 0, 200],
        /*material: {
            ambient: 0.35,
            diffuse: 0.6,
            shininess: 32,
            specularColor: [30, 30, 30]
        }*/
    })]

    return newLayers
}

export default getTreeLayer;