<script>
    export let geoJson;
    export let centerCoords;
    export let treeList = [];

    import { onMount } from "svelte";
    import {deckGlConfig} from '../../config'

    import {Deck} from '@deck.gl/core';
    import {FlyToInterpolator} from '@deck.gl/core';

    import getTileLayer from './layer/tile'
    import getHexagonLayer from './layer/hexagon'
    import getIconLayer from './layer/icon'
    import getTreeLayer from './layer/tree'

    let deckgl
    let mapElement
    let layers = [];

    const INITIAL_VIEW_STATE = {
        latitude: 51.163375, 
        longitude: 10.447683,
        zoom: 5,
        pitch: 60,
        bearing: 0
    }
    let initialCenter = false;
    const setCenter = (center) => {
        
        if(!deckgl || initialCenter) return;

        deckgl.setProps({
            initialViewState: {...INITIAL_VIEW_STATE, longitude: center[0], latitude: center[1], zoom: 16}
        })
        initialCenter = true;
    }

    export const resetCenter = () => {
       
    }
    export const zoomOut = (coordinates) => {
        console.log('zooming Out');
        
        deckgl.setProps({
            initialViewState: {
                ...INITIAL_VIEW_STATE,
               
                zoom: 5,
               
                transitionDuration: "auto",
                transitionInterpolator: new FlyToInterpolator({speed: 1})
            }
        });
    }
    export const centerOn = (coordinates, newZoom=17) => {
        /*deckgl.setProps({
            initialViewState: {...$mapStatus.view, ...{
                longitude: obj.longitude,
                latitude: obj.latitude,
                zoom: diffZoom < 7 ? viewport.zoom : Math.min(obj.zoom, 19),
                pitch: viewport.pitch,
                bearing: viewport.bearing,
                offset: [200, 200],
                transitionDuration: "auto",
                transitionInterpolator: new FlyToInterpolator({speed: 2})
            }}
        });*/
        console.log('zoom ' + newZoom);
        deckgl.setProps({
            initialViewState: {
                longitude: coordinates[0],
                latitude: coordinates[1],
                zoom: newZoom || 17,
                pitch: 60,
                bearing: 0,
                offset: [200, 200],
                transitionDuration: "auto",
                transitionInterpolator: new FlyToInterpolator({speed: 1})
            }
        });
        
    }

    const update = (helmetState, treeList) => {
        if(!deckgl) return;
        
        layers = [];

        if(deckGlConfig.TILE_SERVER)
            layers.push(getTileLayer(deckGlConfig.TILE_SERVER))

        if(helmetState){
            if(Object.hasOwn(helmetState, 'locationTimestamp')) {
                layers.push(getIconLayer('icon-layer', [helmetState]));
            }
        }

        if(treeList){
            layers.push(getTreeLayer('tree-layer', treeList));
        }
            
           
        deckgl.setProps({layers: layers});
    }

    const _getTooltip = ({object}) => {
        return object && object.type && object.type === 'tree' && {
            className: 'sdg-tooltip',
            html: `<div>
                        <p class="sdg-tooltip-value">Species: ${object.species}</p>
                        <p class="sdg-tooltip-value">Height: ${object.height} m</p>
                        <p class="sdg-tooltip-value">DBH: ${object.dbh} cm</p>
                    </div>`
        }
    }
    onMount(() => {

        deckgl = new Deck({
            parent: mapElement,
            initialViewState: INITIAL_VIEW_STATE,
            controller: {},
            layers: layers,
            getTooltip: _getTooltip
        });

        update();
    })

    $: [
        update(geoJson, treeList),
        setCenter(centerCoords)
    ]

</script>

<div class="mapWrapper">
    <div id="deckGlMap" class="map" bind:this={mapElement}></div>
</div>
<slot></slot>
    
<style>
    
    .mapWrapper{
        height: 100%;
        width: 100%;
        padding: 20px;
    }
    
    #deckGlMap{
        height: 100%;
        width: 100%;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
    }
    :global(.sdg-tooltip){
        text-align: center;
        border-radius: 15px;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
    }
    :global(.sdg-tooltip-value){
        font-size: 1em;
        margin: 5px 0;
    }
    :global(.sdg-tooltip-title){
        font-size: 1em;
    }

    @media only screen and (max-width: 600px)  {
        .mapWrapper{
            padding: 0px;
        }
        #deckGlMap{
            border-radius: 0px;
        }
    }
</style>