<script>
    import {persionalization} from '../config'
</script>

<a id="logo" aria-label="{persionalization.PAGE_URL}" href={persionalization.PAGE_URL} target="blank">
    <div style="background-image: url('{persionalization.LOGO_URL}');"></div>
</a>

<style>
    #logo{
        position: fixed;
        z-index: 10000;
        bottom: 10px;
        right: 10px;
        height: 75px;
        width: 75px;
    }
    #logo::before{
        content: '';
        position: absolute;
        top: -40px;
        left: -40px;
        height: 300px;
        width: 300px;
        background-color: #333;
        transform: rotate(45deg);
        z-index: -1;
        box-shadow: 0 0 3px 3px rgba(0,0,0,.7);
    }
    #logo div{
        height: 100%;
        width: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
</style>