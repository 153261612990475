<script>
    import { onMount } from 'svelte';

    import Logo from './components/logo.svelte'
    import Gun from 'gun/gun'

    import Deckgl from './components/maps/deckgl.svelte'
    import Leaflet from './components/maps/leaflet.svelte';
    import OpenLayers from './components/maps/openlayers.svelte'
    import Shock from './components/alert.svelte'

    import ENV from './config'
    import Helmet from './components/helmet.svelte';
    import Chat from './components/chat.svelte';
    import FovisLogo from './components/fovis-logo.svelte';
    import Screen from './components/screen.svelte';

    let center;
    let rotateObject;
    let changeState;
    let screenState;
    let resetCenter;
    let centerOn;
    let centerHelmet;
    let chatList = [];
    let chatlistKeys = [];
    let screenlistKeys = [];
    let treeList = [];
    let zoomOut;
    let setScreen;


    const mapFrameworks = {
        deckgl: Deckgl,
        leaflet: Leaflet,
        openlayers: OpenLayers
    }

    /**
     * /etc/letsencrypt/live/db.gruenecho.de/chain.pem
     * /etc/letsencrypt/live/db.gruenecho.de/fullchain.pem
     * /etc/letsencrypt/live/db.gruenecho.de/privkey.pem
     * /etc/letsencrypt/live/db.gruenecho.de/cert.pem
    */

    const gun = Gun(['wss://db.gruenecho.de:8765/gun']);
    let helmetState = {};
   
    function updatePosition() {
        
        if(helmetState.longitude && helmetState.latitude){
            center = [helmetState.longitude, helmetState.latitude];
        }
    }

    
    
    let helmet;
    let lastTimestamp = 0;
    let _timer;
    let now = new Date().getTime();
    onMount(() => {

        //const fovis = gun.get('fovis');
        helmet = gun.get('fovis').get('helmet');
        
        helmet.get('state').on(function(data, key){
            
            if(data.timestamp <= lastTimestamp) return;

            helmetState = data;
            rotateObject(data.yaw, data.pitch, data.roll, data.longitude, data.latitude, centerOn);
            updatePosition(data);
            changeState(data.isListening);
            screenState(data.isListening, data.longitude);

            lastTimestamp = data.timestamp;

            clearTimeout(_timer);
            _timer = setTimeout(() => {
                helmetState = undefined;
                changeState(null);
                screenState(null);
            }, 1000);
        });
        helmet.get('command').on(function(data, key){
            if(data.timestamp < now) return;
            if(data.command === 'zoomOut'){
                zoomOut();
            }
             /*for (const [key, value] of Object.entries(data)) {
                if (key !== '_') {
                    console.log(`${key}: ${value}`);
                    console.log();
                }
            }*/
        });
        helmet.get('screen').on(function(data, key){
            //if(data.timestamp < now) return;
            setScreen(data);
        });
        helmet.get('tree').map().on(function(data, key){
            if(data.timestamp < now) return;
            if(chatlistKeys.indexOf(key) !== -1) return;
            chatlistKeys = [...chatlistKeys, key];
            

            if(data.dbh && data.longitude) {
                
                chatList = [...chatList, {...data, type: 'tree', centerOn:centerOn, key: key}];
                treeList = [...treeList, {...data, type: 'tree'}];
                centerOn([data.longitude, data.latitude], 21);
                
                
            }else if(data.message){
                chatList = [...chatList, {...data, type: 'message', key: key}];
            }
        });
    })

</script>

{#if helmet}
    <Shock gun={helmet}/>
{/if}
<div class="row">
    <div id="io-chat">
        <Screen bind:setScreen={setScreen} bind:changeState={screenState}/>
        <Chat bind:list={chatList}></Chat>
    </div>
    <div id="io-map">
        <svelte:component this={mapFrameworks[ENV.FRAMEWORK]} geoJson={helmetState} centerCoords={center} bind:treeList={treeList} bind:centerOn={centerOn} bind:zoomOut={zoomOut} bind:resetCenter={resetCenter}>
            <Helmet bind:rotateObject={rotateObject} bind:changeState={changeState}/>
            
        </svelte:component>
    </div> 
</div>

<Logo/>
<!--
<FovisLogo/>
-->
<style>
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 100vh;
    }

</style>