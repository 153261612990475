<script>
    import { onMount } from 'svelte';
    //import Banner, { Label, Icon, CloseReason } from '@smui/banner';
    import Dialog, { Title, Content, Actions } from '@smui/dialog';
    import Button, { Label, Icon } from '@smui/button';

    export let gun;

    let open = false;
    let centered = true;
    let mobileStacked = true;

    let shockData = {};

    let now = new Date().getTime();
    

    async function shockNotification()  {
        //const registration = await navigator.serviceWorker.getRegistration();

        if(Notification.permission === "granted"){
            const img = "./asset/icon.png";
            const text = `!!! SHOCK DETECTED !!!`;
            const notification = new Notification("FoVis", { 
                body: text,
                tag: 'shock' + Math.random(),
                img: img,
                icon: img,
                vibrate: [200, 100, 200],
                renotify: true,
            });

            notification.onclick = function() {
                window.open("https://fovis.gruenecho.de/");
            };

            
            //registration.showNotification('SHOCK', notification);

        }
    }

    // Let's check if the browser supports notifications
    if ("Notification" in window) {
        Notification.requestPermission();
    }


    onMount(() => {

        gun.get('command').on(function(data, key){
            
            if(data.command === 'shock'){
                if(data.timestamp < now) return;
                open = true;
                shockNotification();
                now = data.timestamp;
                shockData = data;
            }
            if(data.command === 'close' ) open = false;
            //console.log(data);
        });
    });
    
</script>


<!--<Banner
    fixed
    bind:open
    bind:centered
    bind:mobileStacked
  >
    <Icon slot="icon" class="material-icons">warning</Icon>
    <Label slot="label">A shock was detected in a helmet.</Label>
    <svelte:fragment slot="actions">
        <Button>
            <Label>close</Label>
        </Button>
      <Button variant="raised" href="tel:+4368120809667" disabled>
        <Icon class="material-icons">phone</Icon>
        <Label>call Helmet</Label>
      </Button>

    </svelte:fragment>
</Banner>-->
{#if open}
<Dialog
  bind:open
  scrimClickAction=""
  escapeKeyAction=""
  aria-labelledby="mandatory-title"
  aria-describedby="mandatory-content"
>
    <Title id="mandatory-title">
        <Icon slot="icon" class="material-icons">warning</Icon>
        A shock was detected at helmet "Rudi".
    </Title>
    <Content id="mandatory-content">
        <h3>Time</h3>
        <p>
            {new Date(shockData.timestamp).toISOString()}
        </p>
        <h3>Position</h3>
        <p>
            <b>Latitude:</b> {shockData.latitude}<br/>
            <b>Longitude:</b> {shockData.longitude}<br/>
            <b>Accuracy:</b> {shockData.accuracy} Meter
        </p>
    </Content>
    <Actions>
        <Button>
            <Label>close</Label>
        </Button>
        <Button variant="raised" href="tel:+4368120809667" disabled>
            <Icon class="material-icons">phone</Icon>
            <Label>call Helmet</Label>
        </Button>
    </Actions>
</Dialog>
{/if}
<style>

</style>