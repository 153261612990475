import {IconLayer} from '@deck.gl/layers';
import {ScatterplotLayer} from '@deck.gl/layers';

const ICON_MAPPING = {
    marker: {x: 0, y: 0, width: 128, height: 128, mask: true, anchorY: 128}
};

const getIconLayer = (id, data) => {

    const newLayers = [];

    newLayers.push(
        new ScatterplotLayer({
            id: id + '-accuracy',
            data,
            pickable: false,
            opacity: 0.001,
            stroked: false,
            filled: true,
            radiusScale: 1,
            lineWidthMinPixels: 1,
            getPosition: d => {
                return [d.longitude, d.latitude];
            },
            getRadius: d => {
                return d.accuracy;
            },
            getFillColor: d => [255, 206, 0],
            getLineColor: d => [0, 0, 0],
            getPolygonOffset: ({layerIndex}) => [0, -layerIndex * 1]
          })
    );
    newLayers.push(
        new IconLayer({
            id,
            data,
            pickable: false,
            // iconAtlas and iconMapping are required
            // getIcon: return a string
            iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
            iconMapping: ICON_MAPPING,
            getIcon: d => 'marker',

            sizeScale: 15,
            getTranslation: d => [0, 0, 128],
            getPosition: d => {
                return [d.longitude, d.latitude];
            },
            getSize: d => 5,
            getColor: d => [255,206,0]
        })  
    )

    return newLayers;
}


export default getIconLayer;